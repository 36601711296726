import {fabric} from 'fabric'

// SVG PATHS LIST

export const hoursehead = new fabric.Path("M10630 11425 c-110 -31 -227 -113 -355 -246 -72 -76 -100 -110 -188 -231 -35 -48 -182 -165 -390 -312 l-169 -118 -81 21 c-448 112 -1127 54 -1968 -169 -1070 -284 -2300 -825 -2794 -1231 -38 -31 -131 -112 -205 -179 -442 -399 -518 -464 -713 -604 -344 -248 -755 -443 -1182 -560 -49 -13 -178 -41 -285 -61 -461 -85 -765 -170 -1120 -313 -432 -173 -1035 -513 -1111 -627 -84 -124 -87 -363 -7 -655 111 -407 391 -1007 791 -1695 964 -1657 2575 -3762 3298 -4309 156 -119 248 -153 324 -122 118 50 308 210 428 362 335 426 599 1162 743 2074 22 142 26 202 29 430 1 146 8 310 14 365 43 381 134 695 293 1010 78 154 107 197 307 445 287 359 591 776 881 1210 289 434 427 618 575 766 217 219 486 363 825 440 153 35 149 36 189 -43 42 -81 124 -185 187 -237 118 -98 294 -177 464 -207 36 -6 155 -12 265 -14 277 -4 482 -37 700 -112 325 -113 566 -305 687 -549 45 -92 76 -121 232 -228 264 -179 566 -238 747 -146 35 18 36 18 85 -6 195 -96 365 -7 449 235 14 39 25 76 25 83 0 6 18 39 40 72 52 82 104 196 132 292 29 103 31 285 4 384 -36 131 -70 183 -237 360 -407 432 -638 717 -810 1001 -138 229 -202 402 -245 663 -47 294 -80 359 -267 543 -133 130 -193 214 -255 361 -40 94 -44 111 -39 162 7 81 45 149 119 212 167 141 264 259 341 413 87 174 128 350 128 547 0 204 -37 298 -117 298 -47 0 -77 -31 -193 -196 -108 -153 -275 -320 -386 -386 -97 -58 -245 -115 -245 -94 0 25 47 139 114 276 95 193 175 454 176 568 0 37 -3 44 -27 53 -47 18 -144 20 -203 4z");
export const Trangle = new fabric.Path(`M6246 11214 c-171 -38 -318 -126 -413 -248 -17 -23 -150 -248 -296
-501 -450 -784 -1475 -2554 -3497 -6035 -1144 -1971 -1953 -3367 -1973 -3405
-59 -117 -80 -279 -53 -415 34 -167 93 -276 216 -401 90 -91 168 -141 280
-180 l65 -23 4105 4 c2258 1 4881 6 5830 10 1561 6 1730 8 1773 23 202 69 348
201 442 402 57 121 70 179 70 315 0 98 -4 127 -28 196 -20 61 -161 312 -600
1065 -921 1580 -2526 4344 -3900 6714 l-1275 2200 -83 85 c-94 96 -175 144
-299 180 -100 29 -270 35 -364 14z`);

export const bedge = new fabric.Path(`M5614 12635 c-305 -56 -594 -324 -865 -803 -33 -59 -64 -110 -68
-114 -4 -4 -71 21 -149 57 -313 143 -552 205 -787 206 -255 1 -441 -74 -597
-239 -191 -203 -322 -573 -363 -1022 l-7 -75 -216 3 c-319 5 -515 -20 -721
-94 -133 -47 -222 -104 -322 -203 -98 -99 -152 -192 -194 -335 -73 -253 -44
-611 84 -1023 l19 -62 -52 -17 c-102 -33 -299 -115 -404 -168 -343 -172 -535
-367 -624 -632 -20 -60 -23 -88 -23 -219 1 -127 4 -163 25 -235 62 -224 192
-457 397 -715 l84 -106 -180 -177 c-180 -178 -293 -316 -367 -447 -51 -93
-101 -219 -119 -304 -19 -92 -19 -257 0 -341 23 -96 104 -253 180 -348 127
-158 362 -340 627 -487 59 -33 108 -65 108 -70 0 -6 -16 -44 -36 -85 -307
-637 -311 -1122 -12 -1421 60 -61 170 -140 255 -182 190 -97 490 -173 793
-203 l66 -6 -1 -256 c0 -164 5 -289 13 -347 77 -518 292 -784 705 -869 117
-24 362 -22 523 4 120 20 314 67 426 103 l47 15 27 -72 c227 -606 445 -886
779 -1002 73 -26 93 -28 225 -29 167 0 234 13 394 76 166 65 338 169 535 323
56 45 111 87 121 94 15 11 27 2 101 -75 311 -327 505 -468 762 -555 116 -39
287 -49 405 -24 299 63 574 323 846 798 37 65 71 118 76 118 4 0 51 -20 103
-45 216 -99 390 -159 587 -201 119 -26 367 -26 465 0 327 85 542 348 659 807
18 74 39 168 45 209 10 62 38 248 43 285 1 7 91 10 257 11 303 0 437 17 635
80 224 72 395 208 489 392 54 104 73 165 92 292 36 236 8 474 -105 883 l-19
68 27 11 c15 5 72 27 127 48 200 75 373 161 516 256 106 70 277 242 327 330
146 253 141 539 -13 863 -82 171 -163 296 -323 501 -32 41 -56 82 -55 91 2 8
62 70 133 138 503 475 653 910 449 1302 -121 233 -337 427 -719 649 -69 39
-126 73 -128 75 -2 2 23 61 56 132 81 175 127 302 169 463 44 173 56 393 29
529 -33 161 -99 282 -218 397 -195 190 -480 299 -948 364 -79 11 -147 20 -150
20 -3 0 -5 123 -5 273 0 287 -6 343 -51 527 -161 650 -700 842 -1593 569 -36
-11 -67 -16 -71 -12 -4 5 -25 62 -49 128 -55 158 -195 441 -267 541 -119 166
-224 266 -354 337 -355 194 -791 82 -1324 -341 l-99 -78 -191 191 c-241 241
-397 354 -593 429 -158 61 -303 78 -449 51z`);





export const fullhorse = new fabric.Path(`M10725 10404 c-11 -3 -58 -11 -105 -20 -134 -24 -296 -68 -387 -105
-310 -129 -578 -313 -829 -571 -158 -162 -235 -256 -394 -478 -194 -271 -325
-422 -472 -545 -116 -98 -195 -149 -340 -220 -37 -18 -148 -48 -283 -75 -131
-27 -180 -45 -345 -126 -69 -34 -154 -74 -190 -89 -36 -14 -74 -30 -85 -35
-43 -19 -103 -42 -175 -66 -386 -127 -510 -143 -1070 -144 -365 0 -459 7 -601
45 -702 185 -1140 250 -1689 250 -253 0 -322 -3 -405 -18 -202 -37 -350 -79
-530 -150 -95 -37 -340 -160 -416 -208 -138 -87 -302 -201 -386 -267 -50 -39
-94 -72 -98 -72 -3 0 -61 51 -128 113 -192 177 -338 281 -519 367 -176 84
-271 112 -473 140 -119 17 -184 20 -440 18 l-300 -3 -3 -39 c-3 -40 24 -166
59 -266 43 -127 50 -146 59 -165 5 -11 30 -69 56 -130 120 -284 222 -426 323
-452 29 -7 260 31 441 73 100 23 137 27 280 27 172 0 268 -16 352 -59 54 -28
108 -74 114 -99 4 -15 -9 -69 -34 -142 -150 -440 -150 -781 -1 -1233 65 -197
149 -384 297 -655 21 -38 60 -110 87 -160 194 -362 178 -322 172 -417 -12
-204 -111 -390 -332 -628 -199 -214 -385 -443 -385 -474 0 -5 17 -37 38 -70
21 -34 42 -70 48 -81 10 -19 45 -39 119 -71 447 -189 623 -270 657 -302 36
-33 470 -904 659 -1319 16 -38 36 -81 44 -98 16 -35 74 -177 103 -250 34 -88
90 -278 102 -350 7 -38 17 -75 22 -82 10 -13 87 -37 153 -48 95 -15 179 -59
221 -116 35 -48 31 -101 -16 -241 -32 -94 -38 -122 -30 -139 15 -27 101 -64
183 -78 81 -14 327 -31 463 -31 171 0 324 54 324 116 0 33 -156 237 -595 779
-181 224 -228 289 -323 453 -36 63 -120 235 -144 297 -42 106 -56 145 -73 200
-25 81 -62 179 -92 240 -44 91 -64 159 -73 254 -10 108 -5 148 20 156 39 12
359 -80 515 -148 189 -84 385 -205 385 -239 0 -11 -28 -50 -63 -86 -34 -37
-80 -87 -101 -111 l-38 -43 4 -97 3 -98 45 -50 c25 -27 104 -112 175 -189 72
-77 155 -168 186 -202 34 -38 62 -60 70 -57 13 5 165 304 204 400 61 150 68
179 67 275 -1 109 -23 212 -81 375 -77 220 -125 309 -202 381 -104 95 -225
157 -454 231 -248 80 -293 94 -320 104 -16 6 -50 17 -75 24 -25 7 -61 19 -80
26 -19 6 -47 16 -62 22 -34 11 -36 38 -9 83 11 17 36 62 56 100 21 38 66 121
100 184 82 150 120 220 163 300 19 36 50 91 68 123 19 32 34 60 34 63 0 3 17
36 39 72 21 37 50 88 63 113 44 79 51 90 127 204 133 200 287 376 480 550 68
61 129 114 137 118 9 5 39 -1 77 -17 102 -41 210 -86 242 -100 17 -8 73 -31
125 -52 52 -20 113 -45 135 -54 58 -25 319 -128 598 -236 50 -19 108 -42 127
-50 19 -7 53 -20 75 -28 22 -7 85 -30 140 -50 228 -84 424 -145 530 -165 33
-6 136 -16 230 -21 182 -11 267 -27 383 -68 53 -19 77 -34 87 -52 18 -36 58
-193 144 -580 38 -170 50 -223 71 -330 12 -58 28 -132 35 -165 8 -33 17 -76
20 -95 3 -19 12 -64 20 -100 27 -130 74 -386 105 -580 19 -116 20 -171 20
-771 0 -505 3 -649 13 -657 6 -5 39 -19 72 -30 131 -44 227 -114 291 -212 55
-85 71 -130 95 -260 11 -57 25 -110 32 -116 7 -7 72 -17 154 -23 77 -6 165
-16 195 -21 29 -6 109 -13 178 -17 118 -5 128 -4 185 20 72 30 78 48 42 116
-44 81 -86 200 -124 351 -7 28 -48 70 -220 225 -267 240 -331 319 -394 485
l-24 65 1 515 c1 513 1 516 27 645 35 173 49 220 92 307 l37 73 -3 365 c-3
341 -1 372 19 470 32 156 67 236 127 288 77 68 134 61 635 -87 183 -54 297
-71 605 -91 88 -5 171 -12 185 -15 14 -2 79 -9 145 -15 152 -13 355 -47 414
-69 54 -20 77 -50 123 -164 l33 -82 -1 -245 c-1 -194 -6 -275 -23 -390 -38
-257 -89 -438 -165 -583 -46 -88 -67 -116 -87 -117 -9 0 -54 19 -100 43 l-84
42 -110 -1 c-73 -1 -121 -6 -140 -15 -30 -14 -41 -37 -80 -169 -7 -25 -32 -97
-55 -160 -126 -347 -150 -426 -150 -498 1 -54 9 -67 65 -103 73 -46 97 -52
162 -39 150 30 356 143 560 307 70 57 474 408 563 490 l45 42 1 313 c1 270 24
724 39 748 2 3 8 44 14 90 22 171 99 481 151 610 13 30 23 67 23 83 0 44 -109
250 -172 324 -31 37 -84 90 -116 117 -58 47 -228 136 -261 136 -9 0 -46 9 -82
19 -37 11 -110 31 -162 46 -161 46 -183 53 -255 80 -38 14 -82 30 -96 34 -14
5 -34 13 -45 19 -10 6 -48 25 -84 43 -121 59 -169 96 -176 137 -4 19 -3 184 2
366 11 400 3 618 -28 731 -30 111 -28 136 24 245 41 86 62 131 133 285 12 28
39 86 60 130 110 236 297 675 314 738 27 98 49 297 56 507 9 257 37 413 100
555 29 65 49 83 71 64 22 -18 390 -469 436 -534 l36 -50 5 -215 c6 -222 19
-321 59 -455 38 -122 101 -211 179 -250 63 -32 174 -34 268 -5 227 72 428 291
460 504 8 51 23 894 34 1956 3 240 5 269 23 305 33 69 54 93 110 130 99 66
206 158 260 224 30 36 54 70 54 76 0 7 -17 23 -37 36 -21 13 -48 40 -61 59
-13 19 -29 38 -36 42 -6 4 -52 -11 -101 -32 -171 -73 -421 -169 -473 -181 -36
-8 -47 -5 -157 36 -38 14 -142 50 -230 79 -348 117 -449 136 -700 135 -104 -1
-199 -3 -210 -5z`);






export const seahorse = new fabric.Path(`M4554 12788 c-37 -12 -146 -87 -316 -217 -154 -118 -230 -156 -313
-156 -75 1 -120 22 -243 116 -51 39 -117 81 -145 95 -114 53 -127 31 -138
-221 -8 -196 -16 -238 -56 -286 -65 -76 -164 -114 -358 -135 -130 -14 -224
-33 -252 -52 -8 -5 -3 -34 22 -107 82 -248 71 -389 -44 -532 -24 -31 -102 -98
-191 -164 -149 -111 -150 -112 -150 -152 0 -52 31 -81 190 -182 171 -107 262
-179 310 -243 33 -46 40 -62 40 -102 0 -45 -5 -55 -96 -171 -288 -370 -464
-679 -577 -1009 -212 -621 -149 -1183 192 -1696 91 -137 158 -218 343 -411
149 -156 199 -229 206 -302 4 -45 2 -51 -34 -91 -43 -48 -192 -124 -312 -160
-133 -40 -244 -17 -323 67 -83 88 -106 141 -184 425 -54 195 -81 269 -100 275
-5 1 -41 -25 -80 -58 -273 -238 -569 -327 -909 -273 -161 25 -393 102 -550
181 l-49 25 7 -29 c12 -48 69 -123 197 -258 152 -159 182 -197 216 -272 51
-110 27 -218 -69 -309 -94 -91 -183 -126 -461 -184 -232 -48 -312 -71 -312
-89 0 -17 100 -74 169 -95 29 -9 136 -34 238 -55 102 -22 208 -49 234 -61 126
-55 155 -152 83 -278 -51 -89 -133 -137 -389 -227 -91 -31 -183 -66 -205 -77
-55 -28 -125 -72 -125 -79 0 -10 207 -57 370 -84 190 -31 294 -59 340 -89 77
-51 101 -169 59 -297 -15 -49 -90 -212 -137 -301 -11 -21 195 27 463 107 386
115 532 135 627 84 34 -18 58 -70 58 -126 0 -58 -23 -133 -99 -328 -143 -365
-208 -683 -218 -1067 -8 -306 11 -497 73 -763 223 -948 937 -1761 1909 -2173
368 -156 585 -197 1020 -196 334 0 555 35 845 130 637 211 1126 682 1358 1309
94 255 126 421 125 665 0 391 -124 707 -368 938 -148 139 -310 223 -529 275
-126 30 -448 31 -576 3 -369 -81 -670 -278 -818 -537 -97 -169 -130 -467 -57
-501 52 -24 128 28 225 153 153 196 185 231 257 289 136 110 268 162 413 163
107 2 174 -15 273 -66 227 -117 377 -393 377 -694 0 -446 -239 -847 -613
-1028 -341 -164 -717 -150 -1087 41 -193 100 -316 207 -464 402 -218 291 -354
576 -418 882 -32 151 -32 459 0 606 90 414 330 782 717 1098 294 241 561 387
1099 601 557 221 872 397 1162 646 320 277 508 597 581 989 26 139 23 416 -5
570 -32 178 -85 340 -162 495 -233 474 -628 835 -1340 1225 -465 254 -785 482
-911 648 -99 131 -104 227 -20 395 27 56 80 153 116 216 90 159 125 192 125
119 0 -41 61 -220 103 -305 58 -114 120 -189 198 -239 169 -110 377 -154 969
-209 592 -54 688 -79 935 -245 160 -107 285 -150 439 -150 106 0 199 22 272
65 110 65 195 176 193 254 -1 80 -56 166 -150 238 -281 215 -489 393 -688 588
-288 283 -464 513 -605 792 -96 190 -148 337 -211 593 -52 208 -111 338 -225
489 -72 95 -171 189 -259 246 -120 78 -195 103 -349 115 -120 10 -162 25 -188
65 -21 32 -13 74 32 173 58 124 57 121 31 133 -24 11 -63 0 -337 -96 -130 -45
-225 -56 -258 -29 -31 26 -37 80 -19 182 l14 84 -24 9 c-30 11 -68 10 -109 -3z`);






export const turtle = new fabric.Path(`M9322 9606 c-47 -16 -56 -38 -49 -115 8 -90 54 -225 197 -571 143
-345 197 -504 206 -598 6 -68 6 -70 -56 -208 -34 -76 -65 -143 -69 -147 -4 -4
-18 1 -32 11 -59 46 -157 107 -324 203 -635 363 -1272 578 -1940 653 -452 52
-1563 54 -2265 6 -355 -25 -1078 -160 -1366 -256 -111 -37 -230 -95 -300 -147
l-61 -45 -17 29 c-47 79 -180 244 -265 330 -88 87 -116 107 -341 242 -259 154
-299 171 -383 158 -87 -14 -135 -84 -174 -255 -24 -103 -24 -486 0 -612 36
-190 106 -329 241 -474 l38 -42 -31 -41 c-91 -122 -148 -265 -155 -390 -3 -62
-9 -79 -40 -127 -48 -72 -93 -182 -122 -299 -20 -76 -26 -132 -31 -271 -9
-269 22 -487 87 -623 13 -27 15 -39 7 -42 -7 -1 -68 -8 -136 -15 -342 -32
-751 -129 -993 -235 -183 -81 -342 -193 -587 -414 -240 -218 -361 -438 -361
-657 0 -45 5 -59 31 -88 174 -199 1249 -277 2015 -146 292 50 494 110 689 205
103 51 262 154 348 227 l37 32 83 -80 c134 -131 262 -220 424 -296 61 -28 97
-54 135 -95 139 -147 372 -257 723 -343 99 -24 148 -42 205 -75 203 -118 436
-190 680 -210 52 -5 118 -19 170 -37 161 -56 361 -87 557 -88 51 0 159 -10
240 -22 130 -20 186 -23 458 -23 314 0 386 6 630 50 60 11 105 13 178 6 53 -4
99 -10 102 -13 3 -2 -50 -76 -116 -164 -347 -457 -535 -765 -584 -960 -30
-117 -97 -436 -120 -569 l-18 -100 -76 -83 c-311 -339 -395 -451 -518 -694
-51 -100 -59 -110 -116 -149 -226 -153 -400 -352 -463 -528 -15 -42 -49 -128
-76 -192 -56 -135 -59 -169 -22 -198 34 -27 86 -27 215 -1 307 63 751 245
1089 447 142 85 811 532 1014 677 560 402 875 763 1005 1153 42 123 106 376
131 513 27 146 37 388 21 500 -22 146 -82 304 -165 428 l-35 53 157 103 c165
109 400 281 490 358 29 25 67 65 84 87 41 56 99 224 138 402 l33 146 76 -42
c42 -23 177 -100 301 -170 326 -186 484 -257 810 -363 352 -115 534 -147 708
-123 159 21 495 106 575 146 24 12 49 11 187 -8 l160 -21 41 40 c46 45 112
171 134 259 50 192 43 391 -18 519 -101 212 -379 443 -682 567 -203 82 -551
186 -705 209 -84 12 -408 14 -513 2 l-68 -8 -242 163 -242 164 -27 107 c-15
58 -26 107 -25 108 1 1 38 20 83 42 197 99 399 268 496 417 131 200 268 541
323 805 66 315 65 624 -2 860 -69 243 -311 485 -703 706 -426 240 -953 400
-1118 340z`);
